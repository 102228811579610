@use '../../../styles/_global-variables.scss' as global;

.carrussel {
    color: #fff;
    overflow: hidden;
    position: relative;
    max-width: 1440px;
    margin: 0 auto var(--gapRow);

    :global {
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
        .swiper-wrapper {
            height: 400px;
            padding-bottom: 16px;
        }
        .swiper-pagination {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            bottom: 3px;
        }

        .swiper-pagination-bullet {
            background: #fff;
            border: none;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            opacity: 0.3;
            cursor: pointer;
        }
        .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
        .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
            margin: 0 4px 5px;
        }
        .swiper-pagination-bullet-active,
        .swiper-pagination-bullet:hover {
            opacity: 1;
        }
        .swiper-button-next::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAyMCAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNTMxMjYyIDM0Ljk0MDJDMC41MzA4MzEgMzQuODAxNiAwLjU1Nzc5NyAzNC42NjQyIDAuNjEwNjEzIDM0LjUzNkMwLjY2MzQzIDM0LjQwNzggMC43NDEwNDcgMzQuMjkxMyAwLjgzOTAyIDM0LjE5MzJMMTYuOTEzOSAxNy45OTg4TDAuODQyMDEyIDEuODA2NjJDMC43NDM5MTQgMS43MDc5MyAwLjY2NjIxNiAxLjU5MDkgMC42MTMzNTIgMS40NjIxOEMwLjU2MDQ4NiAxLjMzMzQ3IDAuNTMzNDkxIDEuMTk1NTggMC41MzM5MDkgMS4wNTY0M0MwLjUzNDc0OCAwLjc3NTQwOSAwLjY0NzE5MiAwLjUwNjIyOCAwLjg0NjUgMC4zMDgxMDlDMS4wNDU4MSAwLjEwOTk5IDEuMzE1NjYgLTAuMDAwODM2NTk2IDEuNTk2NjggMy45NjUzOGUtMDZDMS44Nzc3MSAwLjAwMDg0NDUyNyAyLjE0Njg5IDAuMTEzMjg3IDIuMzQ1MDEgMC4zMTI1OTZMMTkuMTYwMiAxNy4yNTMzQzE5LjM1NzggMTcuNDUxNyAxOS40Njg3IDE3LjcyMDMgMTkuNDY4NyAxOC4wMDAzQzE5LjQ2ODcgMTguMjgwMyAxOS4zNTc4IDE4LjU0ODkgMTkuMTYwMiAxOC43NDczTDIuMzQyMDEgMzUuNjg3M0MyLjE5NDEzIDM1LjgzNjEgMi4wMDUzNCAzNS45Mzc3IDEuNzk5NjMgMzUuOTc5MkMxLjU5MzkyIDM2LjAyMDYgMS4zODA1NCAzNiAxLjE4NjU1IDM1LjkyQzAuOTkyNTY5IDM1LjgzOTkgMC44MjY3MjkgMzUuNzA0MSAwLjcxMDA2MiAzNS41Mjk3QzAuNTkzMzk2IDM1LjM1NTIgMC41MzExNjUgMzUuMTUwMSAwLjUzMTI2MiAzNC45NDAyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
        }
        .swiper-button-prev::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAyMCAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjQ2ODcgMzQuOTQwMkMxOS40NjkyIDM0LjgwMTYgMTkuNDQyMiAzNC42NjQyIDE5LjM4OTQgMzQuNTM2QzE5LjMzNjYgMzQuNDA3OCAxOS4yNTkgMzQuMjkxMyAxOS4xNjEgMzQuMTkzMkwzLjA4NjA4IDE3Ljk5ODhMMTkuMTU4IDEuODA2NjJDMTkuMjU2MSAxLjcwNzkzIDE5LjMzMzggMS41OTA5IDE5LjM4NjYgMS40NjIxOEMxOS40Mzk1IDEuMzMzNDcgMTkuNDY2NSAxLjE5NTU4IDE5LjQ2NjEgMS4wNTY0M0MxOS40NjUzIDAuNzc1NDA5IDE5LjM1MjggMC41MDYyMjggMTkuMTUzNSAwLjMwODEwOUMxOC45NTQyIDAuMTA5OTkgMTguNjg0MyAtMC4wMDA4MzY1OTYgMTguNDAzMyAzLjk2NTM4ZS0wNkMxOC4xMjIzIDAuMDAwODQ0NTI3IDE3Ljg1MzEgMC4xMTMyODcgMTcuNjU1IDAuMzEyNTk2TDAuODM5ODEyIDE3LjI1MzNDMC42NDIyMDEgMTcuNDUxNyAwLjUzMTI1MSAxNy43MjAzIDAuNTMxMjUxIDE4LjAwMDNDMC41MzEyNTEgMTguMjgwMyAwLjY0MjIwMSAxOC41NDg5IDAuODM5ODEyIDE4Ljc0NzNMMTcuNjU4IDM1LjY4NzNDMTcuODA1OSAzNS44MzYxIDE3Ljk5NDcgMzUuOTM3NyAxOC4yMDA0IDM1Ljk3OTJDMTguNDA2MSAzNi4wMjA2IDE4LjYxOTUgMzYgMTguODEzNCAzNS45MkMxOS4wMDc0IDM1LjgzOTkgMTkuMTczMyAzNS43MDQxIDE5LjI4OTkgMzUuNTI5N0MxOS40MDY2IDM1LjM1NTIgMTkuNDY4OCAzNS4xNTAxIDE5LjQ2ODcgMzQuOTQwMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
            background-position: right;
        }
    }
    .afegeix {
        position: absolute;
        right: -48px;
        top: 50%;
        margin-top: -16px;
    }
}

.ico_clock {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 56px;
    background: linear-gradient(180deg, transparent 68%, rgba(8, 8, 8, 0.3) 77.5%, #111 96.25%);
}

.descripcio {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 36px;
    left: 0;
    bottom: 32px;
    &:after {
        content: '';
        position: absolute;
        top: 20px;
        right: 40px;
        bottom: 0;
        left: 40px;
        background-color: rgba(0, 0, 0, 0.3);
        filter: blur(50px);
        border-radius: 20%;
        z-index: -1;
    }
}

.etiqueta {
    color: #000;
    background-color: #fff;
    display: inline-block;
    text-transform: uppercase;
    font-size: var(--body_xxs);
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 8px;
    margin-bottom: 16px;
    text-align: center;
}

.infoDistribucio {
    background: rgba(17, 17, 17, 0.6);
    color: global.$color10;
    font-size: var(--body_m);
    display: inline-block;
    font-weight: 700;
    border-radius: 4px;
    padding: 4px 8px;
    margin-bottom: 16px;
    text-align: center;
    @media #{global.$VP_ALL_MOBILE} {
        font-size: var(--body_xs);
    }
}

.titolPrograma {
    font-size: var(--body_l);
    font-weight: 700;
    margin-bottom: 8px;
    text-align: center;
}

.titol {
    font-weight: 300;
    font-size: var(--headline_m);
    line-height: 1.2;
    margin-bottom: 0.4em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
}
.entradeta {
    display: none;
}

.duradaWrapper {
    display: none;
}

.durada {
    display: flex;
    align-items: center;
    gap: 3px;
}

.botoAfegir {
    display: block;
    position: relative;
}

.slide {
    aspect-ratio: 16/9;
    min-height: 0;
    max-width: 1440px;
    visibility: hidden;
    &.init {
        visibility: visible;
    }
}

.logo {
    height: 56px;
    max-width: 140px;
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 16px;
}

.logodirecte {
    display: block;
    margin-bottom: 16px;
    height: 32px;
    position: relative;
    width: 100%;
}

.logo,
.logodirecte {
    margin-bottom: 16px;
    img {
        margin: 0 auto !important;
        min-width: auto !important;
        width: auto !important;
        position: relative !important;
    }
}

.imatgeCarrussel {
    object-position: top;
}

@media not (hover) {
    .carrussel {
        :global {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
        }
    }
}

@media (hover) {
    .carrussel {
        :global {
            .swiper:not(:hover) {
                .swiper-button-prev,
                .swiper-button-next {
                    display: none;
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                width: 46px;
                height: 40px;
                position: absolute;
                left: 0;
                right: auto;
                top: 50%;
                z-index: 10;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.6;
                &::after {
                    content: '';
                    width: 46px;
                    height: 40px;
                    background-position: left;
                    background-size: 36px 36px;
                    background-repeat: no-repeat;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 20%;
                    z-index: -1;
                    filter: blur(20px);
                }
                &:hover {
                    opacity: 1;
                    &:before {
                        opacity: 0.6;
                    }
                }
                &.swiper-button-disabled {
                    display: none;
                }
            }
            .swiper-button-next {
                left: auto;
                right: 0;
            }
        }
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .logo,
    .logodirecte {
        margin-bottom: 16px;
        img {
            margin: 0 auto !important;
            position: relative !important;
        }
    }
    .descripcio {
        height: auto;
        bottom: 16px;
    }
}

// min768
@media #{global.$VP_BEYOND_MOBILE} {
    .overlay {
        padding: 0 56px;
    }
}

// min1024
@media #{global.$VP_BEYOND_TP} {
    .carrussel {
        margin: 0 auto;
        .logo {
            height: 88px;
            max-width: 220px;
        }
        .titol {
            font-size: var(--headline_m);
        }
        .slide {
            aspect-ratio: 48/22;
            max-height: 90vh;
            min-height: calc(100vw / 2.6);
        }
        .logodirecte {
            height: 51px;
        }
        :global {
            .swiper-wrapper {
                height: auto;
            }
            .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
            .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
                margin-inline: 12px;
                margin: 0 8px 5px;
            }
            .swiper-pagination {
                bottom: 8%;
            }
        }
    }
    .overlay {
        display: flex;
        align-items: center;
        padding: 0 36px;
    }
    .descripcio {
        align-items: flex-start;
        width: 50%;
        position: relative;
        padding: 0;
        bottom: 0;
        &:after {
            top: 0;
            right: 40px;
            bottom: 0;
            left: 0;
        }
    }
    .entradeta {
        font-size: var(--body_m);
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 16px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    }
    .duradaWrapper {
        margin-bottom: 16px;
        font-size: var(--body_s);
        display: flex;
        gap: 16px;
        align-items: center;
        line-height: 1;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    }
    .titol,
    .titolPrograma,
    .etiqueta {
        text-align: left;
    }
    .titol {
        font-size: var(--headline_s);
        // padding-bottom: 2px;
        &:hover span {
            border-bottom: 1px solid currentColor;
        }
    }
    .logodirecte {
        height: 38px;
        img {
            margin: 0 !important;
        }
    }
    .logo,
    .logodirecte {
        img {
            position: absolute !important;
            margin: 0 !important;
        }
    }
    .titolPrograma {
        font-size: var(--headline_xs);
        margin-bottom: 16px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    }
    .botoAfegir {
        margin-top: 16px;
    }
}

// 1024-1279

@media #{global.$VP_REGULAR_AND_TL} {
    .carrussel {
        .logo {
            height: 88px;
            max-width: 220px;
        }
        .slide {
            aspect-ratio: 16/8;
        }
    }
}

// min1440
@media #{global.$VP_EXTRA_LARGE} {
    .carrussel {
        max-width: 100%;
        .titol {
            font-size: var(--headline_l);
        }
        .titolPrograma {
            font-size: var(--headline_s);
        }
        .entradeta {
            font-size: var(--body_l);
        }
        .logo {
            height: 124px;
            max-width: 310px;
        }
        .slide {
            max-width: 100%;
        }
        .logodirecte {
            height: 72px;
        }
        .overlay {
            padding-left: 56px;
        }
    }
}

// min1920

@media #{global.$VP_SUPER_EXTRA_LARGE} {
    .carrussel {
        .titol {
            font-size: var(--headline_xl);
        }
        .entradeta {
            font-size: var(--headline_xs);
        }
        .logodirecte {
            height: 93px;
        }
        .etiqueta {
            font-size: var(--body_s);
        }
        .duradaWrapper {
            font-size: var(--body_m);
        }
    }
}

// min2144px

@media #{global.$VP_MASTODONTIC} {
    .carrussel {
        :global {
            .swiper-wrapper {
                padding-inline: 40px;
            }
        }
        max-width: 2218px;
        .slide {
            max-width: 2218px;
        }
        .descripcio {
            padding: 0;
            left: 40px;
        }
        .overlay {
            padding: 0;
            left: -40px;
            background: linear-gradient(180deg, transparent 48%, rgba(8, 8, 8, 0.3) 62.5%, #111 96.25%),
                linear-gradient(90deg, rgba(17, 17, 17, 1) 40px, rgba(17, 17, 17, 0) 10%),
                linear-gradient(270deg, rgba(17, 17, 17, 1) 40px, rgba(17, 17, 17, 0) 10%);
        }
    }
}
